<template>
  <div class="space-y-6">
    <form class="space-y-6" @submit.prevent="handleSubmit">
      <div class="bg-[white] rounded-lg p-4 lg:p-6 mb-6 shadow-sm">
        <h3 class="text-lg font-medium mb-4">
          {{ t('print.contact-details') }}
        </h3>
        <div class="grid gap-4">
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label class="block text-sm font-medium text-[gray] mb-1">{{
                t('print.first-name')
              }}</label>
              <input
                v-model="form.firstName"
                type="text"
                class="w-full p-2 border border-[gray] rounded-lg"
                required
              />
            </div>
            <div>
              <label class="block text-sm font-medium text-[gray] mb-1">{{
                t('print.last-name')
              }}</label>
              <input
                v-model="form.lastName"
                type="text"
                class="w-full p-2 border border-[gray] rounded-lg"
                required
              />
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-[gray] mb-1">{{
              t('print.business-name')
            }}</label>
            <input
              v-model="form.businessName"
              type="text"
              class="w-full p-2 border border-[gray] rounded-lg"
            />
          </div>
          <div>
            <label class="block text-sm font-medium text-[gray] mb-1">{{
              t('print.email')
            }}</label>
            <input
              v-model="form.email"
              type="email"
              class="w-full p-2 border border-[gray] rounded-lg"
              required
            />
          </div>
          <div>
            <label class="block text-sm font-medium text-[gray] mb-1">{{
              t('print.phone')
            }}</label>
            <div class="flex gap-2">
              <select
                v-model="form.phoneCountryCode"
                class="w-20 p-2 border border-[gray] rounded-lg"
              >
                <option value="+1">+1</option>
              </select>
              <input
                v-model="form.phone"
                type="tel"
                class="flex-1 p-2 border border-[gray] rounded-lg"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div class="bg-[white] rounded-lg p-4 lg:p-6 shadow-sm">
        <h3 class="text-lg font-medium mb-4">
          {{ t('print.shipping-details') }}
        </h3>
        <div class="grid gap-4">
          <div>
            <label class="block text-sm font-medium text-[gray] mb-1">{{
              t('print.address-line-1')
            }}</label>
            <input
              v-model="form.address.line1"
              type="text"
              class="w-full p-2 border border-[gray] rounded-lg"
              required
            />
          </div>
          <div>
            <label class="block text-sm font-medium text-[gray] mb-1">{{
              t('print.address-line-2')
            }}</label>
            <input
              v-model="form.address.line2"
              type="text"
              class="w-full p-2 border border-[gray] rounded-lg"
            />
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label class="block text-sm font-medium text-[gray] mb-1">{{
                t('print.city')
              }}</label>
              <input
                v-model="form.address.city"
                type="text"
                class="w-full p-2 border border-[gray] rounded-lg"
                required
              />
            </div>
            <div>
              <label class="block text-sm font-medium text-[gray] mb-1">{{
                t('print.state')
              }}</label>
              <input
                v-model="form.address.state"
                type="text"
                class="w-full p-2 border border-[gray] rounded-lg"
                required
              />
            </div>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label class="block text-sm font-medium text-[gray] mb-1">{{
                t('print.postal-code')
              }}</label>
              <input
                v-model="form.address.postalCode"
                type="text"
                class="w-full p-2 border border-[gray] rounded-lg"
                required
              />
            </div>
            <div>
              <label class="block text-sm font-medium text-[gray] mb-1">{{
                t('print.country')
              }}</label>
              <input
                v-model="form.address.country"
                type="text"
                class="w-full p-2 border border-[gray] rounded-lg"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-between pt-4">
        <button
          type="submit"
          class="px-4 py-2 lg:px-6 bg-primary text-[white] rounded-lg hover:bg-primary-dark transition-colors"
          :disabled="isProcessing"
        >
          <span v-if="isProcessing">{{ t('print.processing') }}...</span>
          <span v-else>{{ t('print.continue-to-delivery') }}</span>
        </button>
      </div>

      <div
        v-if="addressValidationStatus === 'error' && !shippingRatesError"
        class="mt-4"
      >
        <div class="p-4 bg-red-50 border border-[red] rounded-md">
          <p class="text-[red]">{{ t('print.address-validation-failed') }}</p>
        </div>
      </div>

      <div v-if="shippingRatesError" class="mt-4">
        <div class="p-4 bg-red-50 border border-[red] rounded-md">
          <p class="text-[red]">{{ shippingRatesError }}</p>
        </div>
      </div>

      <div
        v-if="
          showAddressConfirmation &&
          addressValidation &&
          addressValidation.suggestedAddress
        "
        class="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 p-4"
      >
        <div
          class="bg-[white] rounded-lg p-6 max-w-md w-full shadow-xl border border-gray"
        >
          <h3 class="text-xl font-semibold mb-4">
            {{ t('print.confirm-address') }}
          </h3>

          <div class="mb-6">
            <p class="text-sm text-gray-700 mb-4">
              {{ t('print.address-suggestion-message') }}
            </p>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div class="border border-gray rounded-lg p-4 bg-gray-50">
                <h4 class="font-medium text-sm mb-2 text-gray-700">
                  {{ t('print.your-address') }}
                </h4>
                <p class="text-sm font-medium">{{ form.address.line1 }}</p>
                <p v-if="form.address.line2" class="text-sm">
                  {{ form.address.line2 }}
                </p>
                <p class="text-sm">
                  {{ form.address.city }}, {{ form.address.state }}
                  {{ form.address.postalCode }}
                </p>
              </div>

              <div class="border-2 border-primary rounded-lg p-4 bg-primary/5">
                <h4 class="font-medium text-sm mb-2 text-primary">
                  {{ t('print.suggested-address') }}
                </h4>
                <p class="text-sm font-medium">
                  {{ addressValidation?.suggestedAddress?.line1 }}
                </p>
                <p
                  v-if="addressValidation?.suggestedAddress?.line2"
                  class="text-sm"
                >
                  {{ addressValidation?.suggestedAddress?.line2 }}
                </p>
                <p class="text-sm">
                  {{ addressValidation?.suggestedAddress?.city }},
                  {{ addressValidation?.suggestedAddress?.state }}
                  {{ addressValidation?.suggestedAddress?.postal_code }}
                </p>
              </div>
            </div>
          </div>

          <div class="flex justify-end space-x-3 w-full">
            <button
              type="button"
              class="flex-1 px-4 py-3 border border-gray rounded-lg hover:bg-gray-50 transition-colors"
              @click="rejectSuggestedAddress"
            >
              {{ t('print.use-my-address') }}
            </button>
            <button
              type="button"
              class="flex-1 px-4 py-3 bg-primary text-[white] rounded-lg hover:bg-primary-dark transition-colors"
              @click="acceptSuggestedAddress"
            >
              {{ t('print.use-suggested-address') }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { usePrintStore } from '~/store/print';
import { apiValidateAddress, apiGetShippingRates } from '~/api/print';

const { t } = useI18n();
const printStore = usePrintStore();
const emit = defineEmits<{
  (e: 'next'): void;
}>();

const form = ref({
  firstName: '',
  lastName: '',
  businessName: '',
  email: '',
  phoneCountryCode: '+1',
  phone: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'US',
  },
});

const addressValidation = ref<{
  isValid: boolean;
  suggestedAddress?: {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    postal_code: string;
  };
  error?: string;
} | null>(null);

const addressValidationStatus = ref<'idle' | 'validating' | 'error'>('idle');
const isProcessing = ref(false);
const isAddressValidated = ref(false);
const shippingRatesError = ref<string | null>(null);

const showAddressConfirmation = ref(false);
const useOriginalAddress = ref(false);

const canValidateAddress = computed(() => {
  return Boolean(
    form.value.address.line1 &&
      form.value.address.city &&
      form.value.address.state &&
      form.value.address.postalCode,
  );
});

const validateAddress = async () => {
  if (!canValidateAddress.value) return false;

  isProcessing.value = true;
  addressValidationStatus.value = 'validating';
  shippingRatesError.value = null;
  isAddressValidated.value = false;
  showAddressConfirmation.value = false;

  try {
    const result = await apiValidateAddress({
      line1: form.value.address.line1,
      line2: form.value.address.line2,
      city: form.value.address.city,
      state: form.value.address.state,
      postalCode: form.value.address.postalCode,
      country: form.value.address.country,
    });

    if (result.resolved && result.resolvedAddress) {
      addressValidation.value = {
        isValid: true,
        suggestedAddress: {
          line1: result.resolvedAddress.streetLines?.[0] || '',
          line2: result.resolvedAddress.streetLines?.[1] || undefined,
          city: result.resolvedAddress.city || '',
          state: result.resolvedAddress.stateOrProvinceCode || '',
          postal_code: result.resolvedAddress.postalCode || '',
        },
      };

      if (addressValidation.value.suggestedAddress) {
        const isAddressDifferent =
          addressValidation.value.suggestedAddress.line1 !==
            form.value.address.line1 ||
          addressValidation.value.suggestedAddress.city !==
            form.value.address.city ||
          addressValidation.value.suggestedAddress.state !==
            form.value.address.state ||
          addressValidation.value.suggestedAddress.postal_code !==
            form.value.address.postalCode;

        if (isAddressDifferent) {
          showAddressConfirmation.value = true;
          addressValidationStatus.value = 'idle';
          isProcessing.value = false;
          return false;
        }
      }

      addressValidationStatus.value = 'idle';
      isAddressValidated.value = true;
      return true;
    }

    addressValidation.value = {
      isValid: false,
      error: result.notifications?.[0]?.message || 'Invalid address',
    };
    addressValidationStatus.value = 'error';
    return false;
  } catch {
    addressValidation.value = null;
    addressValidationStatus.value = 'error';
    return false;
  } finally {
    if (!showAddressConfirmation.value) {
      isProcessing.value = false;
    }
  }
};

const validateShippingRates = async () => {
  if (!canValidateAddress.value) return false;

  shippingRatesError.value = null;

  try {
    const items = Object.entries(printStore.selectedItems)
      .map(([key, item]) => {
        const quantity = printStore.quantities[key] || 0;
        return {
          id: key,
          weight: item.printProduct.weightOz
            ? item.printProduct.weightOz / 16
            : 0.1,
          quantity,
        };
      })
      .filter((item) => item.quantity > 0);

    const totalWeight = items.reduce(
      (sum, item) => sum + item.weight * item.quantity,
      0,
    );

    await apiGetShippingRates({
      toAddress: {
        postalCode: form.value.address.postalCode,
        country: form.value.address.country,
      },
      weight: Math.max(totalWeight, 0.1),
      items,
    });

    return true;
  } catch (err: any) {
    shippingRatesError.value =
      err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : 'Shipping is not available for this address. Please try a different address.';
    return false;
  }
};

const submitForm = () => {
  printStore.setShippingAddress({
    name: `${form.value.firstName} ${form.value.lastName}`.trim(),
    email: form.value.email,
    phone: `${form.value.phoneCountryCode}${form.value.phone}`,
    businessName: form.value.businessName,
    address: {
      line1: form.value.address.line1,
      line2: form.value.address.line2,
      city: form.value.address.city,
      state: form.value.address.state,
      postal_code: form.value.address.postalCode,
      country: form.value.address.country,
    },
  });

  printStore.setCheckoutStep(2);
};

const acceptSuggestedAddress = () => {
  if (
    addressValidation.value?.isValid &&
    addressValidation.value?.suggestedAddress
  ) {
    form.value.address = {
      ...form.value.address,
      line1: addressValidation.value.suggestedAddress.line1,
      line2: addressValidation.value.suggestedAddress.line2 || '',
      city: addressValidation.value.suggestedAddress.city,
      state: addressValidation.value.suggestedAddress.state,
      postalCode: addressValidation.value.suggestedAddress.postal_code,
    };
  }

  showAddressConfirmation.value = false;
  isAddressValidated.value = true;
  isProcessing.value = false;

  submitForm();
};

const rejectSuggestedAddress = () => {
  useOriginalAddress.value = true;
  showAddressConfirmation.value = false;
  isAddressValidated.value = true;
  isProcessing.value = false;

  submitForm();
};

const handleSubmit = async () => {
  useOriginalAddress.value = false;

  const isValid = await validateAddress();
  if (!isValid && !showAddressConfirmation.value) return;

  if (showAddressConfirmation.value) return;

  const hasValidRates = await validateShippingRates();
  if (!hasValidRates) return;

  submitForm();
};

onMounted(() => {
  if (printStore.checkout.shippingAddress.name) {
    const { shippingAddress } = printStore.checkout;
    const nameParts = shippingAddress.name.split(' ');
    const firstName = nameParts[0] || '';
    const lastName = nameParts.slice(1).join(' ') || '';

    form.value = {
      firstName,
      lastName,
      businessName: shippingAddress.businessName || '',
      email: shippingAddress.email || '',
      phoneCountryCode: '+1',
      phone: shippingAddress.phone?.replace('+1', '') || '',
      address: {
        line1: shippingAddress.address.line1 || '',
        line2: shippingAddress.address.line2 || '',
        city: shippingAddress.address.city || '',
        state: shippingAddress.address.state || '',
        postalCode: shippingAddress.address.postal_code || '',
        country: shippingAddress.address.country || 'US',
      },
    };
  }
});
</script>
